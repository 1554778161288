import React, {useEffect} from 'react'
import tw, {GlobalStyles, styled} from 'twin.macro'
import {Helmet} from 'react-helmet'
import {Match} from '@reach/router'

import Transition from './Transition'
import Menu from './Menu'
import Footer from './Footer'

const Skip = styled.a`
  ${tw`font-sans`}
  padding: 0 1rem;
  line-height: 60px;
  background: #2867cf;
  color: white;
  z-index: 101;
  position: fixed;
  top: -100%;
  &:hover {
    text-decoration: underline;
  }
  &:focus,
  &:active,
  &:hover {
    top: 0;
  }
`

const Root = styled.div`
  ${tw`antialiased 2xl:(max-w-screen-2xl mx-auto)`}
  /* TODO this should be applied to the whole body */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-display: fallback;
  font-style: normal;
  @supports (font-variation-settings: normal) {
    ${tw`font-sans font-normal`}
  }

  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) textarea:focus,
  body:not(.user-is-tabbing) a:focus {
    outline: none;
  }
`

const Layout = (props) => {
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing')
    }
  }
  useEffect(() => window.addEventListener('keydown', handleFirstTab), [])

  useEffect(() =>
    document.documentElement.style.setProperty(
      '--vh',
      `${window.innerHeight / 100}px`
    )
  )

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <Skip href="#main" id="skip-navigation">
        Skip to content
      </Skip>
      <Match path="/">{(props) => (props.match ? null : <Menu />)}</Match>
      <Transition {...props}>
        <Root>{props.children}</Root>
        <Footer />
      </Transition>
    </>
  )
}

export default Layout
