import React from 'react'
import tw, {styled} from 'twin.macro'
import {Link} from 'gatsby'
import {AnimatePresence, motion} from 'framer-motion'
import {GatsbyImage} from 'gatsby-plugin-image'
import LogoType from '../../icons/LogoType.js'
import HomeMenu from '../general/HomeMenu'

const Container = styled.div`
  display: grid;
  grid-column-start: 3;
  z-index: 50;
  justify-content: end;
  align-content: center;
  padding: 8vw 6vw 10rem 0;
  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
    padding: 4rem 2rem 10rem 0;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    grid-column-start: 1;
    grid-gap: 0.75em;
    padding: 0 0 0 4rem;
    /* grid-column: 1 / -1; */
  }
`

const Title = styled.ul`
  margin: 0 0 1.5rem 0;
  line-height: 1.1;
  letter-spacing: -0.01em;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: 1.1rem;
  color: ${(props) => props.theme.colors.accent};
  & ::selection {
    background-color: ${(props) => props.theme.colors.accent};
    color: ${(props) => props.theme.colors.primary};
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    font-size: 1.3rem;
  }
`

const Card = styled(motion.div)`
  width: 100%;
  text-align: right;
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    grid-column: 1 / -1;
    padding-right: 0;
    padding-left: 2rem;
    text-align: left;
  }
`

const LogoContainer = styled(motion.div)`
  width: 14rem;
  padding-bottom: 16vw;
  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
    width: 16rem;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
    width: 16rem;
    padding-bottom: 2rem;
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    width: 20rem;
    padding-bottom: 4rem;
  }
`

const duration = 1.44

const variants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.33,
      delay: i * 0.35,
      ease: [0.22, 1, 0.36, 1],
    },
  }),
  hidden: {opacity: 0},
}

// TODO Checkout dynamic variants in Framer Motion https://www.framer.com/docs/animation/
const Intro = (props) => {
  const listItems = props.text.map((text, i) => (
    <motion.li
      custom={i}
      animate="visible"
      initial="initial"
      variants={variants}
    >
      {text}
    </motion.li>
  ))

  return (
    <Container>
      <Card>
        <Title>{listItems}</Title>
      </Card>

      <LogoContainer
        initial={{y: 25, opacity: 0}}
        animate={{y: 0, opacity: 1}}
        transition={{delay: 2.1, ease: [0.22, 1, 0.36, 1], duration: 2}}
      >
        <LogoType color="#083322"></LogoType>
      </LogoContainer>

      <Card
        initial={{opacity: 0}}
        animate={{opacity: 1}}
        transition={{delay: 3.1, ease: [0.22, 1, 0.36, 1], duration: 2}}
      >
        <HomeMenu />
        {/* TODO make this sync up with the regular menu */}
      </Card>
    </Container>
  )
}

export default Intro
