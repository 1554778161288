import React from 'react'
import tw, {styled} from 'twin.macro'
import SEO from '../components/general/SEO'
import Intro from '../components/index/Intro'
import FeaturedList from '../components/templates/FeaturedList'
import {graphql} from 'gatsby'
import {getSrc} from 'gatsby-plugin-image'
import {GatsbyImage, withArtDirection, getImage} from 'gatsby-plugin-image'
import HomeMenu from '../components/general/HomeMenu'
import FloatMenu from '../components/general/FloatMenu'

const IndexPage = ({data}) => {
  const text = data.home.descriptions
  const cover = data.home.cover
  const coververtical = data.home.coververtical
  const ogImage = getSrc(cover)
  const posts = data.allContentfulProject.edges

  const Wrapper = styled.div`
    /* TODO See if can limit this height calc to iOS only (dpi media query?) */
    height: calc(var(--vh, 1vh) * 100);
    ${tw`2xl:(2xl:max-w-screen-2xl mx-auto)`};
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr 60vw;
    grid-gap: 0.75em;

    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      height: 100vh;
      grid-template-rows: 1fr;
      grid-template-columns: 1fr 1fr 1fr;
    }
  `

  const BgImg = styled(GatsbyImage)`
    grid-area: 1/1;
    position: absolute;
    width: 100%;
    ${tw`2xl:(2xl:max-w-screen-2xl mx-auto)`};
    height: calc(var(--vh, 1vh) * 100);
    z-index: 40;
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      height: 100vh;
    }
    & > picture > img {
      object-fit: cover !important;
      object-position: 40% 50% !important;
    }
    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      & > picture > img {
        object-fit: cover !important;
        object-position: 50% 50% !important;
      }
    }
  `

  const images = withArtDirection(getImage(data.home.cover), [
    {
      media: '(max-width: 50em)',
      image: getImage(data.home.coververtical),
    },
  ])

  return (
    <>
      <SEO image={ogImage} />
      <Wrapper>
        <Intro text={text}></Intro>
        <BgImg className="art-directed" image={images} alt={`alt`} />
      </Wrapper>
      <FloatMenu />
      <FeaturedList {...data.home} />
    </>
  )
}

// TODO Make it so the homepage work if a fragment is missing.
export const query = graphql`
  query {
    home: contentfulHome(slug: {eq: "home"}) {
      title
      features {
        ...EventItemFragment
      }
      asides {
        ...NewsItemFragment
        ...QuoteItemFragment
      }
      slug
      coververtical {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: NONE
          backgroundColor: "#637871"
          quality: 95
        )
      }
      cover {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: NONE
          backgroundColor: "#637871"
          quality: 95
        )
      }
      descriptions
    }

    allContentfulProject(limit: 1000, sort: {fields: [date], order: DESC}) {
      edges {
        node {
          title
          id
          slug
          date(formatString: "MMMM DD, YYYY")
          cover {
            title
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              aspectRatio: 1.75
            )
          }
          body {
            childMarkdownRemark {
              html
              excerpt(format: PLAIN)
            }
          }
        }
      }
    }
  }
`

export default IndexPage
