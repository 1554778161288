import React from 'react'
import SEO from '../components/general/SEO'
import Form from '../components/contact/Form'
import tw, {styled} from 'twin.macro'

import LogoScroll from '../components/general/LogoScroll'

const Wrapper = styled.div`
  ${tw`my-96 md:w-3/4 lg:w-1/2`}
`
const ContactPage = () => {
  return (
    <>
      <SEO title="Contact" description="Contact Pauline" />
      <LogoScroll noHero />
      <Wrapper>
        <Form />
      </Wrapper>
    </>
  )
}

export default ContactPage
