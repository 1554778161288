import React from 'react'
import tw, {styled} from 'twin.macro'
import {GatsbyImage} from 'gatsby-plugin-image'
import LogoType from '../../icons/LogoType.js'

import ScrollAnimation from '../general/ScrollAnimation'
import {scale} from '../../utils/utils'

const Container = tw.div``

const LogoContainer = tw.div`fixed mix-blend-hard-light filter saturate-0 h-auto top-16 left-5 w-5/12 md:(top-24 left-16 w-64) lg:w-72`

const Card = tw.div``

const BgImg = styled(GatsbyImage)`
  grid-area: 1/1;
  position: absolute;
  width: 100%;
  ${tw`2xl:(2xl:max-w-screen-2xl mx-auto)`};
  height: calc(var(--vh, 1vh) * 80);
  z-index: 0;

  & > picture > img {
    object-fit: cover !important;
    object-position: 50% 50% !important;
  }
`

// const Container = styled.div(({hasFade}) => [
//   tw`flex w-full`, // Add base styles first
//   hasBg && 'height: 300px;', // Then add conditional styles
// ])

const Scrim = styled.div`
  height: 50%;
  width: 100%;
  height: calc(var(--vh, 1vh) * 80);
  bottom: calc(var(--vh, 1vh) - 0);
  left: 0;
  /* bottom: 0; */
  position: absolute;
  background: linear-gradient(
    to top,
    #121212 0%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.15) 65%,
    rgba(0, 0, 0, 0.075) 75.5%,
    rgba(0, 0, 0, 0.037) 82.85%,
    rgba(0, 0, 0, 0.019) 88%,
    rgba(0, 0, 0, 0) 100%
  );
`

const Profile = (props) => {
  return (
    <ScrollAnimation
      render={({progress}) => {
        let fadeOut = scale(progress, 0.9, 0)
        return (
          <Container tw="h-48">
            <BgImg image={props.cover.gatsbyImageData} alt={`alt`} />
            <Scrim />
            <LogoContainer
              style={{
                opacity: fadeOut,
              }}
            >
              <LogoType color={props.logoColor}></LogoType>
            </LogoContainer>
          </Container>
        )
      }}
    />
  )
}

export default Profile
