import React from 'react'
import SEO from '../components/general/SEO'
import ItemList from '../components/templates/ItemList'
import Profile from '../components/about/Profile'
import {graphql} from 'gatsby'
import {getSrc} from 'gatsby-plugin-image'
import tw, {styled} from 'twin.macro'

import LogoScroll from '../components/general/LogoScroll'

const Wrapper = tw.div`h-screen80`

const Group = styled.div`
  ${tw`px-8 md:px-16 md:pt-6`}
`

const DisplayTitle = styled.h1`
  ${tw`text-gray-lightest text-4xl leading-tight md:leading-tight tracking-tight font-bold `}
`

const DisplaySub = styled.h2`
  ${tw`text-gray-lightest text-xl leading-tight md:leading-tight tracking-tight font-bold max-w-xs`}
`

const Kit = styled.div`
  ${tw`text-base`}
`

const NewsPage = ({data}) => {
  const news = data.allContentfulNews.edges
  const ogImage = getSrc(news[0].node.cover)
  const title = data.contentfulPage.title
  const subtitle = data.contentfulPage.subtitle
  const content = data.contentfulPage.content
  const cover = data.contentfulPage.cover

  return (
    <>
      <SEO title="News" description="" image={ogImage} />
      <Wrapper>
        <Profile cover={cover} />
      </Wrapper>
      <Group>
        <div tw="col-span-12 mb-8">
          <DisplayTitle>{title}</DisplayTitle>
          <DisplaySub>{subtitle}</DisplaySub>
        </div>

        {content ? (
          <Kit
            tw="col-span-12 md:col-span-6"
            dangerouslySetInnerHTML={{__html: content.childMarkdownRemark.html}}
          ></Kit>
        ) : null}
      </Group>
      {/* TODO read props to items below (like event page) */}
      <div tw="">
        <ItemList parentslug="news" posts={news} />
      </div>
    </>
  )
}

export const query = graphql`
  query {
    contentfulPage(slug: {eq: "news"}) {
      title
      subtitle
      slug
      cover {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: NONE
          backgroundColor: "#000000"
          quality: 82
        )
      }
      content {
        childMarkdownRemark {
          html
          excerpt(format: PLAIN)
        }
      }
    }

    allContentfulNews(limit: 1000, sort: {fields: [date], order: DESC}) {
      edges {
        node {
          title
          id
          slug
          colourTheme
          venue
          date(formatString: "dddd, Do MMMM YYYY")
          cover {
            title
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: NONE
              backgroundColor: "#000000"
              quality: 82
            )
          }
          body {
            childMarkdownRemark {
              html
              excerpt(format: PLAIN)
            }
          }
        }
      }
    }
  }
`

export default NewsPage
