import React from 'react'
import Content from '../components/templates/Content'
import Hero from '../components/templates/Hero'
import Preview from '../components/templates/Preview'
import BigText from '../components/about/BigText'
import SEO from '../components/general/SEO'
import {getSrc} from 'gatsby-plugin-image'
import tw from 'twin.macro'

const EventTemplate = ({pageContext}) => {
  const {title, date, eventDate, cover, previous, body, venue, slug, sys} =
    pageContext
  const ogImage = getSrc(cover)
  const Wrapper = tw.div`h-screen80`

  return (
    <>
      <SEO
        title={title}
        description={body.childMarkdownRemark.excerpt}
        image={ogImage}
      />
      <Wrapper>
        <Hero image={cover} />
      </Wrapper>
      <BigText title={title} date={eventDate} venue={venue} />
      <Content markdown={body} />
      <Preview post={previous} />
    </>
  )
}

export default EventTemplate
