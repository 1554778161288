import React, {useState} from 'react'
import {Link} from 'gatsby'
import tw, {styled} from 'twin.macro'
import {motion} from 'framer-motion'
import ColorSwitch from './ColorSwitch'

const Header = styled.header``
const Nav = styled.nav``

const List = styled.ul`
  margin: 0 0 1.5rem 0;
  line-height: 1.1;
  letter-spacing: -0.01em;
  font-weight: ${(props) => props.theme.fontWeights.bold};
  font-size: 1.1rem;
  color: ${(props) => props.theme.colors.accent};
  & ::selection {
    background-color: ${(props) => props.theme.colors.accent};
    color: ${(props) => props.theme.colors.primary};
  }
  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    font-size: 1.3rem;
  }
`

const Item = styled(motion.li)`
  opacity: 1 !important;
  pointer-events: auto;
  padding-top: 0.5rem;

  a {
    position: relative;
    text-decoration: none;
    color: ${(props) => props.theme.colors.muted};
    transition: color 0.3s;

    &:hover {
      color: ${(props) => props.theme.colors.text};
    }
  }
`

const HomeMenu = () => {
  return (
    <Header>
      <Nav>
        <List>
          <Item>
            <Link to="/about/">About</Link>
          </Item>
          <Item>
            <Link to="/event/">Events</Link>
          </Item>
          <Item>
            <Link to="/project/">Projects</Link>
          </Item>
          <Item>
            <Link to="/workshops-mentoring/">Workshops</Link>
          </Item>

          <Item>
            <Link to="/news/">News</Link>
          </Item>

          <Item>
            <Link to="/contact/">Contact</Link>
          </Item>
        </List>
      </Nav>
    </Header>
  )
}

export default HomeMenu
