import React from 'react'
import tw, {styled} from 'twin.macro'

const Container = tw.div``

const Card = tw.div``

const Vid = styled.video`
  grid-area: 1/1;
  position: absolute;
  width: 100%;
  /* object-fit: cover; */
  ${tw`2xl:(2xl:max-w-screen-2xl mx-auto) object-cover	`};
  height: calc(var(--vh, 1vh) * 80);
  z-index: 0;

  & > video {
    object-fit: cover !important;
    object-position: 50% 50% !important;
  }
`

// const Container = styled.div(({hasFade}) => [
//   tw`flex w-full`, // Add base styles first
//   hasBg && 'height: 300px;', // Then add conditional styles
// ])

const Scrim = styled.div`
  height: 50%;
  width: 100%;
  height: calc(var(--vh, 1vh) * 80);
  bottom: calc(var(--vh, 1vh) - 0);
  left: 0;
  /* bottom: 0; */
  position: absolute;
  background: linear-gradient(
    to top,
    #121212 0%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.15) 65%,
    rgba(0, 0, 0, 0.075) 75.5%,
    rgba(0, 0, 0, 0.037) 82.85%,
    rgba(0, 0, 0, 0.019) 88%,
    rgba(0, 0, 0, 0) 100%
  );
`

const VideoCover = (props) => {
  return (
    <Container>
      <Vid
        preload="auto"
        loop
        autoPlay
        muted
        playsInline
        src={props.video.file.url}
      />
      <Scrim />
    </Container>
  )
}

export default VideoCover
