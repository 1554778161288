import React from 'react'
import {Helmet} from 'react-helmet'
import Content from '../components/templates/Content'
import Hero from '../components/templates/Hero'
import Preview from '../components/templates/Preview'
import BigText from '../components/about/BigText'
import SEO from '../components/general/SEO'
import {getSrc} from 'gatsby-plugin-image'
import tw from 'twin.macro'

const NewsTemplate = ({pageContext}) => {
  const {title, date, eventDate, cover, previous, body, slug, sys} = pageContext
  const ogImage = getSrc(cover)
  const Wrapper = tw.div`h-screen80`

  return (
    <>
      <Helmet>
        <script
          async
          src="//cdn.embedly.com/widgets/platform.js"
          charset="UTF-8"
        ></script>
      </Helmet>
      <SEO
        title={title}
        description={body.childMarkdownRemark.excerpt}
        image={ogImage}
      />
      <Wrapper>
        <Hero title={title} date={eventDate} image={cover} />
      </Wrapper>
      <BigText title={title} date={eventDate} />
      <Content markdown={body} />
      <Preview post={previous} />
    </>
  )
}

export default NewsTemplate
