import React from 'react'
import SEO from '../components/general/SEO'
import Content from '../components/templates/Content'
import Profile from '../components/about/Profile'
import BigText from '../components/about/BigText'
import {graphql} from 'gatsby'
import {getSrc} from 'gatsby-plugin-image'
import tw, {styled} from 'twin.macro'

const AboutPage = ({data}) => {
  const title = data.contentfulPage.title
  const subtitle = data.contentfulPage.subtitle
  const cover = data.contentfulPage.cover
  const content = data.contentfulPage.content
  const ogImage = getSrc(cover)

  const Wrapper = tw.div`h-screen80`

  return (
    <>
      <SEO title="About" description={content.excerpt} image={ogImage} />
      <Wrapper>
        <Profile cover={cover} />
      </Wrapper>
      <BigText title={title} />
      <Content markdown={content} />
    </>
  )
}

export const query = graphql`
  query {
    contentfulPage(slug: {eq: "about"}) {
      title
      subtitle
      slug
      cover {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: NONE
          backgroundColor: "#000000"
          quality: 82
        )
      }
      content {
        childMarkdownRemark {
          html
          excerpt(format: PLAIN)
        }
      }
    }
  }
`

export default AboutPage
