import React, {useState} from 'react'
import {Link} from 'gatsby'
import {motion} from 'framer-motion'
import tw, {styled, theme} from 'twin.macro'

import LogoType from '../../icons/LogoType.js'
import ScrollAnimation from './ScrollAnimation.js'
import {scale} from '../../utils/utils.js'

const Container = tw.div``

const LogoContainer = tw.div`fixed mix-blend-hard-light filter saturate-0 h-auto top-16 left-5 w-5/12 md:(top-24 left-16 w-64) lg:w-72`

const LogoScroll = ({noHero}) => {
  return (
    <ScrollAnimation
      render={({progress}) => {
        let fadeOut = scale(progress, 0.9, 0)
        return (
          <Container
            css={[
              tw`h-96`, // Add base styles first
              noHero && tw`h-48`, // Then add conditional styles
            ]}
          >
            <LogoContainer
              style={{
                opacity: fadeOut,
              }}
            >
              <LogoType color="rgba(255,255,255,0.9"></LogoType>
            </LogoContainer>
          </Container>
        )
      }}
    />
  )
}

export default LogoScroll
