import {graphql} from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import tw, {styled} from 'twin.macro'
import {GatsbyImage} from 'gatsby-plugin-image'

const BlockItemVariants = {
  // Named class sets
  featured: tw` `,
  aside: tw` `,
  sm: tw`h-32`,
  md: tw`h-64`,
  lg: tw`h-80`,
  Blue: tw`bg-blue-900 text-blue-600`,
  Green: tw`bg-leaf-light text-leaf-dark`,
  Purple: tw`bg-purple-dark text-purple-dark`,
  GrayGreen: tw`bg-gray-lightest text-leaf-dark`,
  GrayPurple: tw`bg-leaf-light text-leaf-dark`,
  Yellow: tw`text-white`,
  BlackGray: tw`text-gray-lightest`,
}

const BlockItem = styled.div(() => [
  tw``,
  ({variant = 'aside'}) => BlockItemVariants[variant], // Grab the variant style via a prop
  {
    boxShadow: `
  0 -2.8px 2.2px rgba(0, 0, 0, 0.014),
  0 -6.7px 5.3px rgba(0, 0, 0, 0.02),
  0 -12.5px 10px rgba(0, 0, 0, 0.025),
  0 -22.3px 17.9px rgba(0, 0, 0, 0.03),
  0 -41.8px 33.4px rgba(0, 0, 0, 0.036),
  0 -100px 80px rgba(0, 0, 0, 0.05)
;
`,
  },
])
const BlockItemText = tw.div``

// TODO Text highlight colours, figure out best way to handle.
// see if there's a way to combine this into tailwind.config ?
const BlockItemQuote = styled.div`
  & ::selection {
    background-color: ${(props) => props.theme.colors.accent};
    color: ${(props) => props.theme.colors.primary};
  }
  > blockquote::before {
    content: open-quote;
    margin-left: -1.15rem;
  }
  > blockquote::after {
    content: close-quote;
  }
  > blockquote {
    quotes: '“' '”' '‘' '’';
  }
  > blockquote > p {
    display: inline;
  }
`

// Set content properties for pseudo elements
tw.div`before:(content["“" "”" "‘" "’"] block)`

const AsideItem = (props) => {
  const {
    title,
    subtitle,
    eventDate,
    venue,
    cover,
    colourTheme,
    quote,
    slug,
    sys,
  } = props
  return (
    // <BlockItem variant={colourTheme} css={[tw`sticky top-4`]}>
    <BlockItem
      className="group"
      variant={colourTheme}
      tw="md:(rounded rounded-lg col-start-2 col-end-3 row-span-1) overflow-hidden"
    >
      {quote ? (
        <BlockItemText
          className="group"
          tw="flex flex-col justify-around h-full font-serif font-normal p-10 md:(px-8 py-4) lg:(px-12 py-8)"
        >
          <BlockItemQuote
            tw="text-3xl  md:(text-[2.4vw] leading-tight) lg:text-2xl xl:text-3xl break-words"
            {...props}
            dangerouslySetInnerHTML={{
              __html: quote.childMarkdownRemark.html,
            }}
          />
          <div tw="text-gray-dark group-hocus:opacity-100 transition-opacity opacity-75 lg:text-lg">
            {title}
          </div>
        </BlockItemText>
      ) : null}
      {cover ? (
        <Link
          to={`/${sys.contentType.sys.id}/${slug}/`}
          tw="flex h-64 relative md:h-full"
        >
          <BlockItemText tw="flex flex-col justify-between font-bold text-sm text-shadow[0px 0px 1px hsl(0deg 0% 0% / 80%)]">
            <div tw="p-4 z-10 w-2/3">
              <div tw="uppercase text-xs tracking-wide text-white text-opacity-80 group-hocus:text-opacity-100 ">
                {sys.contentType.sys.id}
              </div>
              <div>{title}</div>
              <div>{subtitle}</div>
              {/* If there's a quote, render this */}
            </div>
            <div tw="p-4 z-10">
              {/* <div>{date}</div> */}
              {/* <div>{venue}</div>{' '} */}
              <div tw="uppercase text-xs tracking-wide text-white mix-blend-difference text-opacity-80 group-hocus:text-opacity-100">
                More
              </div>
            </div>
          </BlockItemText>
          <blockfull tw="absolute z-0 top-0 left-0 w-full h-full">
            <GatsbyImage
              image={cover.gatsbyImageData}
              objectFit="cover"
              objectPosition="center 35%"
              tw="w-full h-full opacity-70 group-hocus:opacity-100 transition-opacity duration-500 transform-gpu"
            ></GatsbyImage>
          </blockfull>
        </Link>
      ) : null}{' '}
    </BlockItem>
  )
}

AsideItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  colourTheme: PropTypes.string.isRequired,
  eventDate: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  venue: PropTypes.string.isRequired,
  sys: PropTypes.string.isRequired,
  cover: PropTypes.shape({
    title: PropTypes.string.isRequired,
    // localFile: PropTypes.object,
  }),
}

export default AsideItem
