import {graphql} from 'gatsby'
import React from 'react'
import PropTypes from 'prop-types'
import Link from 'gatsby-link'
import tw, {styled, css} from 'twin.macro'
import {GatsbyImage} from 'gatsby-plugin-image'

const BlockItemVariants = {
  // Named class sets
  featured: tw``,
  aside: tw` `,
  sm: tw`h-32`,
  md: tw`h-64`,
  lg: tw`h-80`,
  Blue: tw`bg-blue-100 text-blue-700`,
  Green: tw`bg-leaf-light text-leaf-dark hocus:(bg-leaf-dark text-leaf-light)`,
  Purple: tw`bg-purple-light text-purple-dark hocus:(bg-purple text-purple-light)`,
  WhiteGray: tw`bg-gray-light text-background`,
  BlackGray: tw`bg-black text-gray-light`,
}

const BlockItem = styled.div(() => [
  ({variant = 'featured'}) => BlockItemVariants[variant], // Grab the variant style via a prop
])
const BlockItemText = tw.div``

const FeaturedItem = (props) => {
  const {
    title,
    subtitle,
    eventDate,
    venue,
    venueShortLocation,
    cover,
    colourTheme,
    sys,
    slug,
  } = props
  return (
    <BlockItem
      className="group"
      variant={colourTheme}
      tw="md:(col-start-1 col-end-2 row-span-2 rounded rounded-lg) overflow-hidden transition-all duration-500"
    >
      <Link to={`/${sys.contentType.sys.id}/${slug}/`} tw="flex h-full">
        <BlockItemText tw="flex flex-col justify-between font-bold text-sm w-7/12">
          <div tw="p-4">
            <div tw="text-[3vw] leading-none tracking-tight md:(text-base leading-none)  lg:(text-xl leading-none) ">
              <div>{title}</div>
              <div>{subtitle}</div>
            </div>
            <div tw="pt-2 ">
              <div tw="text-sm">{eventDate}</div>
              <div tw="text-sm">{venueShortLocation}</div>{' '}
            </div>
          </div>

          <div tw="p-4">
            <div tw="uppercase text-xs lg:text-sm tracking-wide text-gray-darkest text-opacity-90 group-hocus:text-gray-lightest">
              More
            </div>
          </div>
        </BlockItemText>
        <GatsbyImage
          image={cover.gatsbyImageData}
          objectFit="cover"
          objectPosition=""
          tw="flex-grow w-full"
        ></GatsbyImage>
      </Link>
    </BlockItem>
  )
}

FeaturedItem.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  colourTheme: PropTypes.string.isRequired,
  eventDate: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  venue: PropTypes.string.isRequired,
  venueShortLocation: PropTypes.string.isRequired,
  cover: PropTypes.shape({
    title: PropTypes.string.isRequired,
    // localFile: PropTypes.object,
  }),
}

export default FeaturedItem
