import scrollDelay from './src/gatsby/browser/shouldUpdateScroll'
import CustomLayout from './src/gatsby/browser/wrapPageElement'
import '@fontsource/inter/variable.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'
import '@fontsource/eb-garamond/400.css'
import '@fontsource/eb-garamond/400-italic.css'
import '@fontsource/eb-garamond/variable.css'
import '@fontsource/eb-garamond/variable-italic.css'
export const wrapPageElement = CustomLayout
export const shouldUpdateScroll = scrollDelay
