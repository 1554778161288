import React from 'react'
import {graphql} from 'gatsby'
import SEO from '../components/general/SEO'
import ProjectsList from '../components/templates/ProjectsList'
import {getSrc} from 'gatsby-plugin-image'
import tw, {styled} from 'twin.macro'

import LogoScroll from '../components/general/LogoScroll'

const ProjectsPage = ({data}) => {
  const projects = data.allContentfulProject.edges
  const ogImage = getSrc(projects[0].node.cover)

  return (
    <>
      <SEO title="Projects" description="" image={ogImage} />
      <LogoScroll noHero />
      <div tw="mt-96">
        <ProjectsList parentslug="projects" projects={projects} />
      </div>
    </>
  )
}

export const query = graphql`
  query {
    allContentfulProject(limit: 1000, sort: {fields: [date], order: DESC}) {
      edges {
        node {
          title
          id
          slug
          cover {
            title
            gatsbyImageData(
              width: 1500
              placeholder: BLURRED
              aspectRatio: 1.5
              quality: 92
            )
          }
          body {
            childMarkdownRemark {
              html
              excerpt(format: PLAIN)
            }
          }
          metaDescription {
            childMarkdownRemark {
              html
              excerpt(format: PLAIN)
            }
          }
        }
      }
    }
  }
`

export default ProjectsPage
