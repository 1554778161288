import React from 'react'
import {Helmet} from 'react-helmet'
import Content from '../components/templates/Content'
import Hero from '../components/templates/Hero'
import ImageList from '../components/templates/ImageList'
import SEO from '../components/general/SEO'
import Preview from '../components/templates/Preview'
import Video from '../components/templates/Video'
import VideoCover from '../components/templates/VideoCover'
import {getSrc} from 'gatsby-plugin-image'
import tw from 'twin.macro'
import BigText from '../components/about/BigText'

const ProjectTemplate = ({pageContext}) => {
  const {title, category, images, cover, previous, body, video, url} =
    pageContext

  const ogImage = getSrc(cover)
  const Wrapper = tw.div`h-screen80`

  return (
    <>
      <Helmet>
        <script
          async
          src="//cdn.embedly.com/widgets/platform.js"
          charset="UTF-8"
        ></script>
      </Helmet>
      <SEO
        title={title}
        description={body.childMarkdownRemark.excerpt}
        image={ogImage}
      />
      <Wrapper>
        {video ? (
          <VideoCover cover={cover} video={video} />
        ) : (
          <Hero title={title} category={category} image={cover} url={url} />
        )}
      </Wrapper>
      <BigText title={title} />
      <Content markdown={body} />

      {/* {video && <Video video={video} />} */}
      <ImageList images={images} />
      <Preview post={previous} />
    </>
  )
}

export default ProjectTemplate
