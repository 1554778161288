import React from 'react'
import SEO from '../components/general/SEO'
import ItemList from '../components/templates/ItemList'
import {graphql} from 'gatsby'
import {getSrc} from 'gatsby-plugin-image'
import tw, {styled} from 'twin.macro'

import LogoScroll from '../components/general/LogoScroll'

const EventPage = ({data}) => {
  const event = data.allContentfulEvent.edges
  const ogImage = getSrc(event[0].node.cover)

  return (
    <>
      <SEO title="Events" description="" image={ogImage} />
      <LogoScroll nohero />
      <div tw="mt-48">
        <ItemList title="Events" parentslug="event" posts={event} />
      </div>
    </>
  )
}

export const query = graphql`
  query {
    allContentfulEvent(limit: 1000, sort: {fields: [eventDate], order: DESC}) {
      edges {
        node {
          title
          id
          slug
          colourTheme
          venue
          venueShortLocation
          date(formatString: "dddd, Do MMMM YYYY")
          eventDate(formatString: "dddd, Do MMMM YYYY")
          cover {
            title
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: NONE
              backgroundColor: "#000000"
              quality: 82
            )
          }
          body {
            childMarkdownRemark {
              html
              excerpt(format: PLAIN)
            }
          }
        }
      }
    }
  }
`

export default EventPage
