import React, {useState} from 'react'
import Link from 'gatsby-link'
import tw, {styled} from 'twin.macro'
import {GatsbyImage} from 'gatsby-plugin-image'
import {motion, AnimateSharedLayout, AnimatePresence} from 'framer-motion'

const Wrapper = styled.div`
  ${tw`px-8 md:px-16 md:pt-6`}
`

const Header = styled.div`
  ${tw`mb-8`}
`

const Title = styled.h1`
  ${tw`text-gray-lightest text-4xl leading-tight md:leading-tight tracking-tight font-bold `}
`
const List = styled.ul`
  ${tw`grid grid-cols-1 gap-6`}
`

const Item = styled(motion.li)`
  ${tw`border-b border-gray last:border-none`}
  :nth-child(even) {
    a {
      flex-direction: row-reverse;
    }
  }
`

const TextWrap = styled.div`
  ${tw`md:w-1/2 lg:w-5/12 self-center`}
`

const Img = styled(GatsbyImage)`
  ${tw`md:w-1/2 lg:w-7/12`}
`
const Heading = styled.h2`
  ${tw`transition-colors duration-300 text-2xl lg:text-3xl font-bold`}
`

const Description = styled.p`
  ${tw`pt-6 transition-colors duration-300 text-base font-medium`}
`

const ProjectLink = styled(Link)`
  ${tw`md:flex gap-6 pb-6`}
  &:hover * h2 {
    ${tw`text-blue-600`}
  }
  &:hover * picture {
    ${tw`opacity-90 transition-opacity`}
  }
`

const ProjectsList = (props) => {
  const [projects, setProjects] = useState(props.projects)

  return (
    <Wrapper>
      <Header>
        <Title>Projects</Title>
      </Header>
      <List>
        <AnimateSharedLayout>
          <AnimatePresence>
            {projects.map(({node: project}) => (
              <Item
                key={project.id}
                layoutId={project.id}
                exit={{opacity: 0}}
                animate={{opacity: 1}}
              >
                <ProjectLink to={`/project/${project.slug}/`}>
                  <Img
                    alt={project.cover.title}
                    image={project.cover.gatsbyImageData}
                  />
                  <TextWrap>
                    <Heading>{project.title}</Heading>
                    <Description
                      dangerouslySetInnerHTML={{
                        __html:
                          project.metaDescription.childMarkdownRemark.html,
                      }}
                    ></Description>
                  </TextWrap>
                </ProjectLink>
              </Item>
            ))}
          </AnimatePresence>
        </AnimateSharedLayout>
      </List>
    </Wrapper>
  )
}

export default ProjectsList
