import {graphql} from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import FeaturedItem from './FeaturedItem'
import AsideItem from './AsideItem'
import tw, {styled} from 'twin.macro'

import NewsletterSignup from './NewsletterSignup'

const Wrapper = tw.div`pt-4 md:p-8 xl:p-16 max-w-screen-2xl mx-auto`

const HomepageBlockColumnVariants = {
  // Named class sets
  featured: tw``,
  aside: tw``,
}

const HomepageBlockColumn = styled.div(() => [
  tw``,
  ({variant = 'featured'}) => HomepageBlockColumnVariants[variant], // Grab the variant style via a prop
])

const HomepageBlockContainer = tw.div``

const FeaturedList = (data) => {
  const {title, features, asides} = data
  return (
    <Wrapper>
      <HomepageBlockContainer
        tw="grid grid-template-columns[1fr] md:(grid-template-columns[6fr 5fr] grid-flow-row-dense)

      md:grid-template-rows[repeat(6, 1fr)]
      gap-4 "
      >
        {features.map((featuredItem) => (
          <FeaturedItem {...featuredItem} />
        ))}

        {asides.map((asideItem) => (
          <AsideItem {...asideItem} />
        ))}

        <NewsletterSignup />
      </HomepageBlockContainer>
    </Wrapper>
  )
}

export default FeaturedList

export const query = graphql`
  fragment NewsItemFragment on ContentfulNews {
    id
    title
    slug
    colourTheme
    cover {
      title
      gatsbyImageData(
        aspectRatio: 1.5
        cropFocus: FACE
        layout: CONSTRAINED
        placeholder: BLURRED
        quality: 82
        resizingBehavior: FILL
        width: 580
      )
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
  fragment ProjectItemFragment on ContentfulProject {
    id
    title
    slug
    colourTheme
    cover {
      title
      gatsbyImageData(
        cropFocus: FACE
        aspectRatio: 0.78
        height: 615
        layout: CONSTRAINED
        placeholder: BLURRED
        resizingBehavior: FILL
        quality: 82
      )
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
  fragment EventItemFragment on ContentfulEvent {
    id
    title
    slug
    colourTheme
    eventDate(formatString: "ddd, Do MMM YYYY")
    venue
    venueShortLocation
    cover {
      title
      gatsbyImageData(
        aspectRatio: 0.78
        cropFocus: FACE
        height: 816
        layout: CONSTRAINED
        placeholder: BLURRED
        quality: 82
        resizingBehavior: FILL
      )
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
  fragment QuoteItemFragment on ContentfulQuote {
    author
    id
    title
    colourTheme
    quote {
      childMarkdownRemark {
        html
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`
