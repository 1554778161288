export default {
  useColorSchemeMediaQuery: false,
  colors: {
    text: '#ffffff',
    reverseText: '#292929',
    background: '#121212',
    primary: '#ffffff',
    secondary: '#5d7368',
    accent: '#083322',
    muted: '#CDD1CC',
    code: '#292929',
  },
  fonts: {
    body: '"InterVariable",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  sizes: {
    maxWidth: '850px',
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '80em',
  },
}
