import React from 'react'
import PropTypes from 'prop-types'
import tw, {styled} from 'twin.macro'
import Link from 'gatsby-link'

/*
  ⚠️ This is an example of a contact form powered with Netlify form handling.
  Be sure to review the Netlify documentation for more information:
  https://www.netlify.com/docs/form-handling/
*/

const Form = styled.form`
  ${tw`flex items-start justify-between my-0 mx-auto active:ring-white flex-wrap `}
  input,
  textarea {
    ${tw`bg-background border border-gray hover:border-gray-lightest active:border-white text-gray-lightest font-semibold rounded p-4`}
    &::-webkit-input-placeholder {
      ${tw`text-gray`}
    }
    &::-moz-placeholder {
      ${tw`text-gray`}
    }
    &:-ms-input-placeholder {
      ${tw`text-gray`}
    }
    &:-moz-placeholder {
      ${tw`text-gray`}
    }
    &:required {
      box-shadow: none;
    }
  }
  &::before {
    content: '';
    background: black;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    transition: 0.2s all;
    opacity: ${(props) => (props.overlay ? '.8' : '0')};
    visibility: ${(props) => (props.overlay ? 'visible' : 'hidden')};
  }
`

const Name = styled.input`
  margin: 0 0 0.5em 0;
  padding: 0.5rem !important;
  font-size: 0.9rem !important;
  width: 100%;
  @media (min-width: ${(props) => props.theme.responsive.medium}) {
    width: 49%;
    margin: 0 0 1em 0;
    padding: 1rem !important;
    font-size: 1rem !important;
  }
`

const Email = styled.input`
  margin: 0 0 0.5em 0;
  padding: 0.5rem !important;
  font-size: 0.9rem !important;
  width: 100%;
  @media (min-width: ${(props) => props.theme.responsive.medium}) {
    width: 49%;
    margin: 0 0 1em 0;
    padding: 1rem !important;
    font-size: 1rem !important;
  }
`

const Message = styled.textarea`
  width: 100%;
  margin: 0 0 1em 0;
  line-height: 1.6;
  min-height: 250px;
  resize: vertical;
`

const Submit = styled.input`
  ${tw`w-full bg-transparent! hover:bg-gray-dark! hover:ring-white hover:ring-2 text-white! font-semibold cursor-pointer transition-all duration-200`}
  }
`

const Modal = styled.div`
  ${tw`bg-gray-darkest font-semibold p-8 rounded fixed`}
  min-width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 99;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  transition: 0.2s all;
  opacity: ${(props) => (props.visible ? '1' : '0')};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  @media screen and (min-width: ${(props) => props.theme.responsive.small}) {
    min-width: inherit;
    max-width: 400px;
  }
  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }
`

const Button = styled.div`
  ${tw`bg-leaf font-semibold text-base mx-auto border-none outline-none cursor-pointer text-white p-4 rounded no-underline transition-all duration-200 z-[99]`}

  &:focus {
    outline: none;
  }
  &:hover {
    background: ${(props) => props.theme.colors.highlight};
  }
`

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const BlockItemText = tw.div``

class NewsletterSignup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      showModal: false,
    }
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }

  handleSubmit = (event) => {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: encode({'form-name': 'contact', ...this.state}),
    })
      .then(this.handleSuccess)
      .catch((error) => alert(error))
    event.preventDefault()
  }

  handleSuccess = () => {
    this.setState({
      name: '',
      email: '',
      message: '',
      showModal: true,
    })
  }

  closeModal = () => {
    this.setState({showModal: false})
  }

  render() {
    return (
      <blockitem
        className="group"
        tw="bg-[#222] md:(rounded rounded-lg col-start-2 col-end-3 row-span-1) overflow-hidden"
      >
        {' '}
        <newsletter tw="flex h-64 relative md:h-full">
          <BlockItemText tw="flex flex-col justify-between font-bold text-sm text-shadow[0px 0px 1 hsl(0deg 0% 0% / 80%)]">
            <div tw="p-2 md:p-4 z-10 w-2/3">
              <div tw="uppercase text-xs tracking-wide text-white text-opacity-80 group-hocus:text-opacity-100 ">
                Newsletter Signup
              </div>
              <div>Enter your details below to get notified of new events</div>
              {/* If there's a quote, render this */}
            </div>
            <div tw="p-2 md:p-4 z-10">
              <Form
                name="contact"
                onSubmit={this.handleSubmit}
                data-netlify="true"
                data-netlify-honeypot="bot"
                overlay={this.state.showModal}
                onClick={this.closeModal}
              >
                <input type="hidden" name="form-name" value="contact" />
                <p hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot" onChange={this.handleInputChange} />
                  </label>
                </p>

                <Name
                  name="name"
                  type="text"
                  placeholder="Name"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                  required
                />
                <Email
                  name="email"
                  type="email"
                  placeholder="Email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  required
                />
                <Submit name="submit" type="submit" value="Subscribe" />

                <Modal visible={this.state.showModal}>
                  <p>Thanks! You've been signed up.</p>
                  <Button onClick={this.closeModal}>Close</Button>
                </Modal>
              </Form>
            </div>
          </BlockItemText>
        </newsletter>
      </blockitem>
    )
  }
}

NewsletterSignup.propTypes = {
  data: PropTypes.object,
}

export default NewsletterSignup
